/* Common Imports */

import React from "react";
import loadable from "@loadable/component";

/* Redux Imports */

import { createStore, applyMiddleware } from "redux";
import GlobalReducer from "../components/redux-components/GlobalReducer";
import { Provider } from "react-redux";
import thunk from "redux-thunk";

/* Create Redux Store */

const reduxStore = createStore(GlobalReducer, applyMiddleware(thunk));

/* Component Imports */

import Layout from "../components/layout-components/Layout";
import TrendingSection from "../components/page-sections/index/TrendingSection";

const KeyNote = loadable(() =>
  import("../components/page-sections/index/KeyNote")
);
const SectionOne = loadable(() =>
  import("../components/page-sections/index/SectionOne")
);
const SectionTwo = loadable(() =>
  import("../components/page-sections/index/SectionTwo")
);
const SectionThree = loadable(() =>
  import("../components/page-sections/index/SectionThree")
);
const SectionFour = loadable(() =>
  import("../components/page-sections/index/SectionFour")
);
const SectionFive = loadable(() =>
  import("../components/page-sections/index/SectionFive")
);
const TestimonialCarousel = loadable(() =>
  import("../components/page-sections/index/TestimonialCarousel")
);
const SectionSix = loadable(() =>
  import("../components/page-sections/index/SectionSix")
);
const ServiceTab = loadable(() =>
  import("../components/page-sections/index/ServiceTab")
);

const Index = () => {
  return (
    <Provider store={reduxStore}>
      <React.Fragment>
        <Layout>
          <TrendingSection />
          <SectionOne />
          <KeyNote />
          <SectionTwo />
          <SectionThree />
          <SectionFour />
          <SectionFive />
          <ServiceTab />
          <TestimonialCarousel />
          <SectionSix data={{ loading: "default" }} />
        </Layout>
      </React.Fragment>
    </Provider>
  );
};

export default Index;
