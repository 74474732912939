import React from "react";
import Searchbar from "../../properties/SearchbarWODropdowns";
// import Slide from "@material-ui/core/Slide";
// import Fade from "@material-ui/core/Fade";
// import useStyles from "../../properties/propertyIndexStyle";
// import ShowHideFilter from "../../properties/ShowHideFilter";
// import ASWrapper from "../../AdvancedSearch/ASWrapper";
// import FilterOptions from "../../AdvancedSearch/DropdownButtons/filterOptions";

const SearchFilters = () => {
  // const classes = useStyles();

  // const [bt, setButton] = React.useState(false);
  // const [ft, setButtonF] = React.useState(true);
  // const [place, setPlace] = React.useState("bengaluru");
  // const [res, setRes] = React.useState("residential");

  return (
    <React.Fragment>
      {/* <div className={classes.root}> */}
      <Searchbar
      // place={(place) => {
      //   setPlace(place);
      // }}
      />
      {/* <div className={classes.optionsButton}>
          <FilterOptions
            res={(res) => {
              setRes(res);
            }}
          />
          <ShowHideFilter
            bt={() => {
              setButton(true);
            }}
            ft={() => {
              setButton(false);
              setButtonF(true);
            }}
          />
        </div>
      </div>
      {bt && ft ? (
        <Slide in={bt} timeout={800}>
          <div>
            <Fade in={bt} timeout={800}>
              <ASWrapper place={place} res={res} />
            </Fade>
          </div>
        </Slide>
      ) : null} */}
    </React.Fragment>
  );
};

export default SearchFilters;
