/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

/* Data Imports */

import AppConfig from "../../../../AppConfig";

/* Define all styles */

const useStyles = makeStyles((theme) => ({
  iconInfoContainerDark: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    fontWeight: 300,
    fontSize: "0.75rem",
    lineHeight: "1.125rem",
    color: "#666666",
  },
  infoIcon: {
    margin: "0rem 0.25rem 0rem 0rem",
  },
  locationSkeleton: {
    width: "50%",
    height: "auto",
    marginBottom: "0.1rem",
    "&.MuiSkeleton-text": {
      transform: "none",
    },
  },
}));

const GridCardLocation = (props) => {
  const classes = useStyles();

  if (!props.loading) {
    return (
      <React.Fragment>
        <Typography
          variant="body2"
          component="span"
          className={classes.iconInfoContainerDark}
        >
          <img
            src="/images/icons/social/location.svg"
            alt={AppConfig.imageAltText}
            width={16}
            height={16}
            loading="lazy"
            referrerPolicy="no-referrer"
            className={classes.infoIcon}
          />
          {props.location ? props.location : "Unknown Location"}
        </Typography>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Skeleton className={classes.locationSkeleton} />
      </React.Fragment>
    );
  }
};

export default GridCardLocation;
