const GridCardPriceData = {
  min: {
    price: 2.45,
    multiplier: 10000000,
    unit: "Crore",
    calculated: 24500000,
  },
  max: {
    price: 2.45,
    multiplier: 10000000,
    unit: "Crore",
    calculated: 24500000,
  },
  discounted: {
    min: {
      price: 2.4,
      multiplier: 10000000,
      unit: "Crore",
      calculated: 2400000,
    },
    max: {
      price: 2.4,
      multiplier: 10000000,
      unit: "Crore",
      calculated: 2400000,
    },
  },
};

export default GridCardPriceData;
