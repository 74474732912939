/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Link } from "@material-ui/core";
import Ribbon from "./Ribbon";
import GridCardImage from "../../properties/sub-components/GridCardImage";
import GridCardPrice from "../../properties/sub-components/GridCardPrice";
import GridCardLocation from "../../properties/sub-components/GridCardLocation";
import GridCardTitle from "../../properties/sub-components/GridCardTitle";

/* Data Imports */

import GridCardPriceData from "../../properties/placeholder-data/GridCardPriceData";

/* Define all styles */

const useStyles = makeStyles((theme) => ({
  gridCardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      height: "17rem",
      margin: "0.5rem 0% 0.5rem 0%",
    },
    [theme.breakpoints.only("xs")]: {
      height: "15rem",
      margin: "0.5rem 0% 0.5rem 0%",
    },
    /* XSSM breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.sm - 170)]: {
      height: "24rem",
      margin: "0.5rem 0.5% 0.5rem 0.5%",
    },
    /* XSSMSM breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.sm - 130)]: {
      height: "29rem",
      margin: "0.5rem 0.5% 0.5rem 0.5%",
    },
    /* XSSMSMSM breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.sm - 60)]: {
      height: "18rem",
      margin: "0.5rem 0.5% 0.5rem 0.5%",
    },
    [theme.breakpoints.up("sm")]: {
      height: "17rem",
      margin: "0.5rem 0.25% 0.5rem 0.25%",
    },
    /* SMMD Breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.md - 80)]: {
      height: "16rem",
      margin: "0.5rem 0.167% 0.5rem 0.167%",
    },
    [theme.breakpoints.up("md")]: {
      height: "20rem",
      margin: "0.5rem 0.167% 0.5rem 0.167%",
    },
    /* MDLG Breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.lg - 240)]: {
      height: "20rem",
      margin: "0.5rem 0.167% 0.5rem 0.167%",
    },
    [theme.breakpoints.up("lg")]: {
      height: "20rem",
      margin: "0.5rem 0.167% 0.5rem 0.167%",
    },
    /* LGXL Breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl - 500)]: {
      height: "25rem",
      margin: "0.5rem 0.167% 0.5rem 0.167%",
    },
    /* LGXLXL Breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl - 50)]: {
      height: "29rem",
      margin: "0.5rem 0.167% 0.5rem 0.167%",
    },
    [theme.breakpoints.up("xl")]: {
      height: "20rem",
      margin: "0.5rem 0.167% 0.5rem 0.167%",
    },
    /* XLXXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 80)]: {
      height: "27rem",
      margin: "0.5rem 0.167% 0.5rem 0.167%",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      height: "22rem",
      margin: "0.5rem 0.167% 0.5rem 0.167%",
    },
    /* XXLXXXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 670)]: {
      height: "29rem",
      margin: "0.5rem 0.167% 0.5rem 0.167%",
    },
    /* XXLXXXLXXXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 1280)]: {
      height: "35rem",
      margin: "0.5rem 0.167% 0.5rem 0.167%",
    },
    /* XXLXXXLXXXLXXXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 1780)]: {
      height: "37rem",
      margin: "0.5rem 0.167% 0.5rem 0.167%",
    },
    /* XXXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
      height: "25rem",
      margin: "0.5rem 0.167% 0.5rem 0.167%",
    },
    borderRadius: "4px",
    boxShadow:
      "0px 0px 2px rgb(0 0 0 / 14%), 0px 2px 2px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%)",
    color: "#202020",
    "&:hover": {
      textDecoration: "none",
    },
  },
  gridCardInfoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "0.5rem 1rem 0.5rem 1rem",
    width: "100%",
    height: "100%",
    borderRadius: "4px",
  },
}));

const PropertyCard = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Link
        href={"/properties/" + (props.slug ? props.slug : "")}
        rel="noopener"
        title={props.title ? props.title : "Unknown Property"}
        className={classes.gridCardContainer}
      >
        <Ribbon text={props.ribbonText} />
        <GridCardImage
          loading={props.loading}
          imageSrc={props.imageSrc ? props.imageSrc : "/images/other/trend.jpg"}
        />
        <div className={classes.gridCardInfoContainer}>
          <GridCardTitle
            loading={props.loading}
            title={props.title ? props.title : "Vaishnavi Terraces Apartments"}
          />
          <GridCardLocation
            loading={props.loading}
            location={props.location ? props.location : "JP Nagar, Bengaluru"}
          />
          <GridCardPrice
            loading={props.loading}
            price={props.price ? props.price : GridCardPriceData}
          />
        </div>
      </Link>
    </React.Fragment>
  );
};

export default PropertyCard;
