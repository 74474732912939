/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import Skeleton from "@material-ui/lab/Skeleton";

/* Data Imports */

import AppConfig from "../../../../AppConfig";

/* Define all styles */

const useStyles = makeStyles((theme) => ({
  gridCardImageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    height: "auto",
    borderRadius: "4px",
  },
  gridCardImage: {
    width: "100%",
    height: "auto",
    borderRadius: "4px",
  },
  imageSkeleton: {
    width: "100%",
    height: "100%",
    borderRadius: "4px",
    "&.MuiSkeleton-text": {
      transform: "none",
    },
  },
}));

const GridCardImage = (props) => {
  const classes = useStyles();

  if (!props.loading) {
    return (
      <React.Fragment>
        <div className={classes.gridCardImageContainer}>
          <img
            src={
              props.imageSrc
                ? props.imageSrc
                : "/images/other/placeholder_1080p.jpg"
            }
            alt={AppConfig.imageAltText}
            width={496}
            height={279}
            loading="lazy"
            referrerPolicy="no-referrer"
            className={classes.gridCardImage}
          />
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Skeleton className={classes.imageSkeleton} />
      </React.Fragment>
    );
  }
};

export default GridCardImage;
