const preparePriceString = (price) => {
  if (price.min.price < price.max.price) {
    return (
      price.min.price +
      " " +
      price.min.unit +
      " to " +
      price.max.price +
      " " +
      price.max.unit
    );
  } else if (price.min.price === price.max.price) {
    return price.min.price + " " + price.min.unit;
  } else if (price.min.price > price.max.price) {
    return price.min.price + " " + price.min.unit + " onwards";
  }
};

export default preparePriceString;
