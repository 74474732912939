/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */
import SearchFilters from "./sub-components/SearchFilters";
import PropertyCard from "./sub-components/PropertyCard";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  megaContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "calc(100vh - 4rem)",
    [theme.breakpoints.only("md")]: {
      height: "100%",
    },
    [theme.breakpoints.only("sm")]: {
      height: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      height: "100%",
      paddingBottom: "4rem",
    },
  },
  more: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    width: "100%",
  },
  moreButton: {
    background: "-webkit-linear-gradient(0deg, #F74134, #EB1C61)",
    color: "#ffffff",
  },
  double: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "noWrap",
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-webkitOverflowScrolling": "touch",
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      height: "22rem",
    },
    [theme.breakpoints.only("xs")]: {
      height: "18rem",
    },
    /* XSSM breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.sm - 60)]: {
      height: "24rem",
    },
    [theme.breakpoints.only("sm")]: {
      height: "23rem",
    },
    [theme.breakpoints.only("md")]: {
      height: "24rem",
    },
    [theme.breakpoints.only("lg")]: {
      height: "27rem",
    },
    [theme.breakpoints.only("xl")]: {
      height: "32rem",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      height: "27rem",
    },
    /* XXXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
      height: "30rem",
    },
  },
  doubleDouble: {
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      width: "90%",
    },
    [theme.breakpoints.only("xs")]: {
      width: "60%",
    },
    /* XSSM breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.sm - 60)]: {
      width: "75%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "48%",
    },
    [theme.breakpoints.only("md")]: {
      width: "45.5%",
    },
    [theme.breakpoints.only("lg")]: {
      width: "33.33333%",
    },
    [theme.breakpoints.only("xl")]: {
      width: "33.33333%",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      width: "33.33333%",
    },
    flex: "0 0 auto",
  },
}));

const BSRPriceData = {
  min: {
    price: 24,
    multiplier: 100000,
    unit: "Lakhs",
    calculated: 2400000,
  },
  max: {
    price: 48,
    multiplier: 100000,
    unit: "Lakhs",
    calculated: 4800000,
  },
  discounted: {
    min: {
      price: 21,
      multiplier: 100000,
      unit: "Lakhs",
      calculated: 2100000,
    },
    max: {
      price: 43.2,
      multiplier: 100000,
      unit: "Lakhs",
      calculated: 4320000,
    },
  },
};

const HeaviePriceData = {
  min: {
    price: 1,
    multiplier: 10000000,
    unit: "Crore",
    calculated: 10000000,
  },
  max: {
    price: 0,
    multiplier: 100000,
    unit: "Lakh",
    calculated: 0,
  },
};

const PrishaPriceData = {
  min: {
    price: 94.93,
    multiplier: 100000,
    unit: "Lakhs",
    calculated: 9493000,
  },
  max: {
    price: 2.16,
    multiplier: 10000000,
    unit: "Crore",
    calculated: 21600000,
  },
};

const SectionOne = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.megaContainer}>
        <SearchFilters />
        <div className={classes.double}>
          <div
            className={classes.doubleDouble}
            style={{ paddingLeft: "0.5rem" }}
          >
            <PropertyCard
              ribbonText="Most Viewed"
              slug="prisha-orchids"
              imageSrc="https://bucket.beegru.in/prisha-orchids-7893cea93dd59e18f055a9396f6348083cac7f8e.webp"
              title="Prisha Orchids"
              location="Thanisandra Main Road"
              price={PrishaPriceData}
            />
          </div>
          <div
            className={classes.doubleDouble}
            style={{ paddingLeft: "0.5rem" }}
          >
            <PropertyCard
              ribbonText="Trending"
              slug="heavie-eden"
              imageSrc="https://bucket.beegru.in/heavie-eden-65b222d378b6a18c937cb50c90af4f39eb008b0c.webp"
              title="Heavie Eden"
              location="Sarjapur Road, Opposite Wipro Corporate Office"
              price={HeaviePriceData}
            />
          </div>
          <div
            className={classes.doubleDouble}
            style={{ paddingLeft: "0.5rem" }}
          >
            <PropertyCard
              ribbonText="Top Selling"
              slug="bsr-akruthi-green-woods"
              imageSrc="https://bucket.beegru.in/akruthi_greenwoods_thumbnail.webp"
              title="BSR Akruthi Green Woods"
              location="Jigani"
              price={BSRPriceData}
            />
          </div>
        </div>
        <div className={classes.more}>
          <Button
            className={classes.moreButton}
            variant="contained"
            href="/properties"
            title="more properties"
            rel="noopener"
          >
            More Properties
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SectionOne;
