/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

/* Define all styles */

const useStyles = makeStyles((theme) => ({
  propertyTitle: {
    fontWeight: 600,
    fontSize: "1rem",
    lineHeight: "1.5rem",
  },
  titleSkeleton: {
    width: "75%",
    height: "auto",
    marginBottom: "0.1rem",
    "&.MuiSkeleton-text": {
      transform: "none",
    },
  },
}));

const GridCardTitle = (props) => {
  const classes = useStyles();

  if (!props.loading) {
    return (
      <React.Fragment>
        <Typography
          variant="body2"
          component="span"
          className={classes.propertyTitle}
        >
          {props.title
            ? props.title.length < 30
              ? props.title
              : props.title.substring(0, 30) + "..."
            : "Untitled Property"}
        </Typography>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Skeleton className={classes.titleSkeleton} />
      </React.Fragment>
    );
  }
};

export default GridCardTitle;
