/* Common Imports */

import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */

import { Typography, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderTopRightRadius: "3px",
    borderTopLeftRadius: "0px",
    borderBottomRightRadius: "3px",
    borderBottomLeftRadius: "0px",
    position: "absolute",
    left: "0",
    top: "0",
    background: "-webkit-linear-gradient(0deg, #F74134, #EB1C61)",
  },
  text: {
    color: "#FFFFFF",
    fontWeight: "bold",
    padding: "1rem",
    fontWeight: 500,
    fontSize: "1rem",
    lineHeight: "1.875rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.125rem",
      lineHeight: "1.6875rem",
    },
    textAlign: "center",
    width: "100%",
  },
  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

const Ribbon = (props) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Typography variant="body2" component="span" className={classes.text}>
          {props.text ? props.text : "TRENDING"}
        </Typography>
      </Paper>
    </React.Fragment>
  );
};

export default Ribbon;
